.members-content {
  padding-top: 10rem;
  display: flex;
  flex-direction: column;
  text-align: center;

  h1 {
    margin: 0 0 2rem;
    font-weight: 700;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.photo-card-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4vw;
  margin-bottom: 3rem;
  gap: 4vw;

  &.center {
    // Use if there are less than 4
    justify-content: center;
  }
}

.photo-credit {
  padding-bottom: 5rem;
  color: #888;
}

.generations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.generation-container {
  width: 60%;
  text-align: justify;
  background-color: white;
  padding: 1rem 3rem 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 60rem) {
  .generation-container {
    width: 80%;
  }
}

@media (max-width: 40rem) {
  .members-content {
    h1 {
      font-size: 1.5rem;
    }
  
    h2 {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  .photo-card-grid {
    justify-content: center;
  }

  .generation-container {
    h2 {
      font-size: 1rem;
    }
  }
}